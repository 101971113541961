<template>
  <b-table-simple class="table" responsive borderless>
    <thead>
      <th>
        <p v-if="isBrasindice()">
          Cód. TISS
        </p>
        <p v-else>
          Cód. Simpro
        </p>
        <p>Cód. TUSS</p>
      </th>
      <th>
        <p>Descrição</p>
        Fabricante
      </th>
      <th>Fração</th>
      <th>Preço fração</th>
      <th>Tabela preço</th>
      <th>Infla/Defla</th>
      <th>Unidade medida</th>
      <th>Preço unidade</th>
      <th>Status</th>
      <th></th>
    </thead>
    <tbody>
      <tr v-for="(expense, index) in expensesCopy" :key="index">
        <td>
          <Ellipsis>
            {{ showCode(expense) }}
          </Ellipsis>
          <Ellipsis>{{ expense?.mat_med?.tuss_code }}</Ellipsis>
        </td>
        <td>
          <Ellipsis>{{ expense?.name }}</Ellipsis>
          <Ellipsis>{{ expense?.mat_med?.manufacturer }}</Ellipsis>
        </td>
        <td>
          <Ellipsis>{{ expense?.mat_med?.fraction }}</Ellipsis>
        </td>
        <td>
          <Ellipsis>{{ showPriceFraction(expense, 'PF') }}</Ellipsis>
          <Ellipsis>{{ showPriceFraction(expense, 'PMC') }}</Ellipsis>
        </td>
        <td>
          <Ellipsis>{{ expense?.tiss_material_medicine_config?.price_formation === "Preço Máximo do Consumidor (PMC)" ? "PMC" : "PF" }}</Ellipsis>
        </td>
        <td>
          <b-input-group append="%">
            <b-input
              :value="expense?.exception?.decrease_or_increase_factor"
              @change="value => editException(index, 'decrease_or_increase_factor', value)"
              class="w-50 input-group-append"
            />
          </b-input-group>
        </td>
        <td>
          <multiselect
            :value="unitMeasures.find(unit => unit.value === expense?.exception?.unit_measurement)"
            @select="e => editException(index, 'unit_measurement', e.value)"
            placeholder="Selecionar"
            :options="unitMeasures"
            track-by="value"
            label="label"
            :searchable="true"
            :allow-empty="false"
            :showLabels="false"
            :showNoResults="false"
            class="with-border multiselect__input"
          >
            <template slot="singleLabel" slot-scope="{ option }">
              <Ellipsis>{{ option.label }}</Ellipsis>
            </template>
          </multiselect>
        </td>
        <td>
          <b-input-group append="R$">
            <b-input
              :value="expense?.exception?.unitary_value"
              @change="value => editException(index, 'unitary_value', value)"
              type="number"
              class="w-50 input-group-append"
            />
          </b-input-group>
        </td>
        <td>
          <toggle-button
            :value="!!expense?.exception?.active"
            @change="e => switchStatus(index)"
            :sync="true"
            :width="40"
            :height="20"
            :color="{ checked: '#00C773', unchecked: '#E1E5F4' }"
          />
        </td>
        <td>
          <b-button
            variant="primary"
            @click="emitSave(expense)"
            :title="!validateExpense(expense) ? 'Preencha a unidade de medida e o preço de unidade para salvar' : 'Salvar configurações'"
            :disabled="!validateExpense(expense)"
          >
            Salvar
          </b-button>
          <Trash class="icon trash ml-2" @click="deleteModal(index, expense) "/>
        </td>
      </tr>
    </tbody>
  </b-table-simple>
</template>

<script>
import { parseNumberToMoney } from '@/utils/moneyHelper'
import unitMeasures from '@/components/Tiss/Data/unitMeasures'

export default {
  components: {
    Ellipsis: () => import('@/components/General/Ellipsis'),
    Trash: () => import('@/assets/icons/trash.svg'),
  },
  props: {
    expenses: Array,
    type: { 
      type: String,
      default: () => 'simpro'
    }, 
    priceFormation: {
      type: String,
      default: () => 'PF'
    }
  },
  data() {
    return {
      unitMeasures: [{
        label: 'Nenhum',
        value: null,
      }].concat(unitMeasures),
      expensesCopy: [],
    }
  },
  methods: {
    isBrasindice() {
      return this.type.toLowerCase() === 'brasindice'
    },
    checkPriceFormation(type) {
      return this.priceFormation.includes(type)
    },
    showCode(expense) {
      return this.isBrasindice() ? expense?.mat_med?.tiss_code : expense?.mat_med?.simpro_code;
    },
    showPriceFraction(expense, type) {
      const config = expense.tiss_material_medicine_config;
      let priceFraction;

      if (config?.table === "brasindice") {
        priceFraction = config?.price_formation === "Preço Máximo do Consumidor (PMC)" ? expense?.mat_med?.pmc_price_fraction : expense?.mat_med?.pfb_price_fraction;
      } else if (config?.table === "simpro") {
        priceFraction = config?.price_formation === "Preço Máximo do Consumidor (PMC)" ?  expense?.mat_med?.price_ven : expense?.mat_med?.price_fab;
      }

      return this.checkPriceFormation(type) ? this.parseNumberToMoney(priceFraction) : '';
    },

    showPrice(expense, type) {
      const config = expense?.tiss_material_medicine_config;
      let price;

      if (config?.table === 'brasindice') {
        price = config?.price_formation === "Preço Máximo do Consumidor (PMC)" ? expense?.mat_med?.pmc_price : expense?.mat_med?.pfb_price;
      } else if (config?.table === 'simpro') {
        price = config?.price_formation === "Preço Máximo do Consumidor (PMC)" ? expense?.mat_med?.pev : expense?.mat_med?.pef;
      }

      return this.checkPriceFormation(type) ? this.parseNumberToMoney(price) : '';
    },
    validateExpense(expense) {
      if (!expense?.exception?.active) return true

      return expense?.exception?.active && ( 
        (expense?.exception?.unit_measurement && expense?.exception?.unitary_value) 
        || (!expense?.exception?.unit_measurement && !expense?.exception?.unitary_value)
      )
    },
    parseNumberToMoney,
    editException(index, key, value) {
      this.expensesCopy[index].exception[key] = value
    },
    switchStatus(index) {
      this.expensesCopy[index].exception.active = !this.expensesCopy[index].exception.active
    },
    emitSave(expense) {
      this.$emit('save', expense)
    },
    async deleteExpenseException(index) {
      this.$emit('delete', index)
    },
    deleteModal(index, expense) {
      this.$swal({
        icon: 'question',
        html: `<div class="body-alert">
          <p>Tem certeza que deseja remover a exceção da despesa <b>${expense?.name}</b>?</p>
          <p>Essa ação não poderá ser desfeita.</p>
        </div>`,
        showCancelButton: true,
        confirmButtonColor: '#F63220',
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
        reverseButtons: true
       }).then(res => {
        if (res.isConfirmed) {
          this.deleteExpenseException(index)
        }
      })
    },
  },
  watch: {
    expenses: {
      handler() {
        this.expensesCopy = this.expenses
      },
      immediate: true,
    },
  },
}
</script>

<style lang="scss" scoped>
  @import '@/assets/scss/table.scss';

  .table {
    thead th {
      vertical-align: middle !important;
    }

    td {
      max-width: 130px !important;
    }

    .input-group-append {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
  }

  .multiselect, input {
    min-width: 120px;
    margin-bottom: 0px !important;
  }
</style>